<template>
  <main id="quiz-results">
    <section class="content">
      <section class="intro">
          <h1 class="main">Resultat</h1>
      </section>
      <article class="floating">
        <h1>Valpåverkan</h1>
        <p>De saker som påverkar dig mycket i gymnasievalet är följande.</p>
        <profilequiz />
    
        <aside class="inline-menu">
          <router-link to="/test">Fler test!</router-link>
          <router-link to="/studieprofil">Till min studieprofil</router-link>
        </aside>
      </article>
    </section>
  </main>
</template>

<script>
import profilequiz from '@/components/profiles/QuizProfile';

export default {
  name: 'quizresults',
  data(){
      return {
          threshold: 20
      }
  },
  components: {
      profilequiz
  },
  computed: {
      paverkan(){
          return this.$store.state.profile.quiz;
      }
  }
}
</script>

<style lang="scss">
@import '../../../scss/variables';

#gardner-results {
    
    .content {

        .intro {
            position: relative;

            aside {
                background: black;
                position: absolute;
                top: 1rem;
                left: 1rem;
                padding: .25rem .5rem;
                border-radius: $radius;
            }
        }
    }
}

</style>

